<template>
  <div class="Spiner" style="z-index=999999">
    <v-overlay :value="dialog">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="7"
        color="#efce6a"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // dialog: true,
    };
  },
  props: ["dialog"],
};
</script>
<style scoped>
.centerProgress {
  top: 50%;
}
</style>
