<template>
  <master-layout>
    <div>
      <!-- Start Event Design -->
      <!-- <v-main class="content-box"> -->
      <div class="mb-3 text-right">
        <v-btn
          v-if="roleName !== 'Agent'"
          class="ml-3 add-btn"
          dark
          @click="$router.push({ name: 'AddEvent' })"
          :disabled="!accessEnableAdd"
        >
          <v-icon>mdi-plus</v-icon> Add Event
        </v-btn>
        <!-- <v-btn class="ml-3 add-btn" dark @click="pushNotification">
          <v-icon>mdi-plus</v-icon> Push Notification
        </v-btn> -->
      </div>
      <v-card>
        <v-row class="ma-0">
          <v-col cols="12" sm="8">
            <v-card-title class="pa-0">
              Event List
              <!-- <v-spacer></v-spacer> -->
            </v-card-title>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              max-width="400px"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-data-table
          item-key="id"
          :headers="registerHeader"
          :items="registrationList"
          class=""
          :loading="loading"
          must-sort
          :search="search"
        >
          <template v-slot:[`header.name`]="{ header }">
            {{ header.text.toUpperCase() }}
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            {{ item.startDate.split(" ")[0] }}
          </template>
          <template v-slot:[`item.endDate`]="{ item }">
            {{ item.endDate.split(" ")[0] }}
          </template>
          <template v-slot:[`item.s3FileUrl`]="{ item }">
            <div class="event-img-box img-fit-box">
              <img
                :src="item.s3FileUrl"
                style="width: 100px"
                :alt="item.fileName"
              />
            </div>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <v-icon style="color: #f0ce6a" v-if="item.isActive"
              >mdi-check-bold</v-icon
            >
            <v-icon style="color: #f0ce6a" v-else>mdi-close-thick</v-icon>
          </template>
          <template v-slot:[`item.isCompleted`]="{ item }">
            <button
              :disabled="accessUnableStatus"
              class="badgeDeactive"
              v-if="item.isCompleted"
              @click="updateStatus(item, false)"
            >
              Completed
            </button>
            <button
              class="badgeActive"
              :disabled="accessUnableStatus"
              v-else
              @click="updateStatus(item, true)"
            >
              Not Completed
            </button>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-if="roleName !== 'Agent'"
                    v-bind="attrs"
                    v-on="on"
                    text
                    :disabled="!accessEnableUpdate"
                    class="edit-pancil"
                    @click="
                      $router.push({
                        name: 'AddEvent',
                        query: {
                          eventId: item.id,
                        },
                      })
                    "
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                Edit Event
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-if="roleName !== 'Agent'"
                    :disabled="accessEnableDetail"
                    v-bind="attrs"
                    v-on="on"
                    text
                    class="edit-pancil"
                    @click="confimationDelete(item.id, true)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                Delete Event
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="accessEnableDetail"
                    v-bind="attrs"
                    v-on="on"
                    text
                    class="edit-pancil"
                    @click="
                      $router.push({
                        name: 'EventDetails',
                        query: {
                          eventId: item.id,
                        },
                      })
                    "
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                Event Detail
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card>

      <!-- <progress-bar :show="loading" />

      <v-simple-table
        fixed-header
        height="600px"
        class="d-none d-sm-block mb-3"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center" width="100px">Image</th>
              <th class="text-center">Event Name</th>
              <th class="text-center">Hotel Name</th>
              <th class="text-center">Address</th>
              <th class="text-center">Start Date</th>
              <th class="text-center">End Date</th>
              <th class="text-center">Time</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr align="center" v-for="item in EventDetail" :key="item.id">
              <td>
                <div class="event-img-box img-fit-box">
                
                  <img
                    src="../../assets/img1.jpg"
                    style="width: 100px"
                    alt=""
                  />
                </div>
              </td>
              <td>{{ item.title }}</td>
              <td>{{ item.hotelName }}</td>
              <td>{{ item.hotelAddress }}t</td>
              <td>{{ item.startDate.split(" ")[0] }}</td>
              <td>{{ item.endDate.split(" ")[0] }}</td>
              <td>{{ item.time }}</td>
              <td>
                <v-btn
                  text
                  class="edit-pancil"
                  @click="
                    $router.push({
                      name: 'AddEvent',
                      query: {
                        eventId: item.id,
                      },
                    })
                  "
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  text
                  class="edit-pancil"
                  @click="
                    $router.push({
                      name: 'EventDetails',
                      query: {
                        eventId: item.id,
                        isAdmin: true,
                      },
                    })
                  "
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table> -->

      <!-- Event Mobile view card -->
      <!-- <v-card
        class="d-sm-none event-mobile_card mb-3"
        elevation="1"
        outlined
        v-for="item in EventDetail"
        :key="item.id"
      >
        <div class="event-name-box text-h6">{{ item.title }}</div>
        <div class="d-flex event-mobile-card-box">
          <div class="event-img">
            <img src="../../assets/img1.jpg" style="max-width: 120px" alt="" />
          </div>
          <div class="event-content">
            <div class="event-hotel text-h6 mb-2 font-18">
              {{ item.hotelName }}
            </div>
            <div class="event-location">
              <v-icon>mdi-map-marker</v-icon> {{ item.hotelAddress }}.
            </div>
          </div>
        </div>
        <div class="time-view">
          <div class="date-time">
            <strong>{{ item.startDate }} To {{ item.endDate }}</strong>
            <v-spacer></v-spacer>
            <label for="">{{ item.time }}</label>
          </div>
          <div class="details-btn">
            <v-btn
              text
              class="details-view"
              @click="
                $router.push({
                  name: 'AddEvent',
                  query: {
                    eventId: item.id,
                    isAdmin: true,
                  },
                })
              "
            >
              <v-icon>mdi-pencil </v-icon>
            </v-btn>
            &nbsp;
            <v-btn
              text
              class="details-view"
              @click="
                $router.push({
                  name: 'EventDetails',
                  query: {
                    eventId: item.id,
                    isAdmin: true,
                  },
                })
              "
            >
              <v-icon>mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
        <span class="dots-box"></span>
      </v-card> -->

      <!-- Event Mobile view card -->
      <!-- </v-main> -->
      <!-- <Footer /> -->
      <!-- End Event Design -->
      <ConfirmationModal
        :dialog="ConfirmDialog"
        :deleteId="deleteId"
        @confirmation="deleteEvent($event)"
      />
      <progress-circular :dialog="loading" />
    </div>
  </master-layout>
</template>

<script>
import EventService from "../../services/EventService.js";
import { actionEnable } from "../../utils/helpers";
import ConfirmationModal from "../../components/Modals/ConfirmationModal.vue";
import ProgressCircular from "../../components/ProgressCircular.vue";
// import ProgressBar from "../../components/ProgressCircular.vue";
export default {
  components: {
    ConfirmationModal,
    ProgressCircular,
  },
  name: "EventManagement",
  data: () => ({
    EventDetail: [],
    roleName: "",
    dialog: false,
    updateEventValue: {},
    loading: false,
    ConfirmDialog: false,
    deleteId: 0,
    search: "",
    registrationList: [],
    isBackClick: 0,
    activeEvent: [],
  }),
  async created() {
    this.getEventList();
    this.roleName = this.$store.state.auth.userState.roleName;
    // await EventService.pushNotificationService();
    this.isBackClick = this.getParameterValues("back") || 0;
  },
  computed: {
    registerHeader() {
      let roleName = this.$store.state.auth.userState.roleName;
      let arr1 = [
        { text: "Image", value: "s3FileUrl", width: 100, sortable: false },
        { text: "Event Name", value: "title", width: 125 },
      ];
      let arr2 = [];
      if (roleName !== "Agent") {
        arr2 = [
          { text: "Hotel Name", value: "hotelName", width: 125 },
          { text: "Hotel Address", value: "hotelAddress", width: 125 },
          { text: "Start Date", value: "startDate", width: 110 },
          { text: "End Date", value: "endDate", width: 110 },
          { text: "Time", value: "time", width: 100, sortable: false },
          { text: "Status", value: "isCompleted", width: 50, sortable: true },
        ];
      }
      let arr3 = [
        { text: "Actions", value: "action", width: 200, sortable: false },
      ];
      return [...arr1, ...arr2, ...arr3];
    },
    accessEnableAdd() {
      return actionEnable("Event", "Add", 1);
    },
    accessEnableUpdate() {
      return actionEnable("Event", "Update", 1);
    },
    accessEnableDetail() {
      return !actionEnable("Event", "Details", 1);
    },
    accessUnableStatus() {
      return !actionEnable("Event", "UpdateEventStatus", 1);
    },
  },
  methods: {
    async getEventList() {
      this.loading = true;
      try {
        const response = await EventService.eventList();
        this.registrationList = response.data.data;
        this.EventDetail = response.data.data;
        let roleName = this.$store.state.auth.userState.roleName;
        this.activeEvent = response.data.data.filter(function (item) {
          return item.isActive && !item.isCompleted;
        });
        if (this.isBackClick === 0) {
          if (roleName === "Agent" && this.activeEvent.length === 1) {
            this.$router.push({
              name: "EventDetails",
              query: {
                eventId: this.activeEvent[0].id,
              },
            });
          }
        }
        if (response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unauthorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    async updateStatus(item, status) {
      try {
        this.loading = true;
        const response = await EventService.getEventStatus({
          eventId: item.id,
          isCompleted: status,
        });
        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "success",
            },
            { root: true }
          );

          this.registrationList = this.registrationList.map((val) => {
            return val.id === item.id
              ? { ...val, isCompleted: status }
              : { ...val };
          });

          this.loading = false;
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unauthorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },

    addOrUpdateEvent(value, item) {
      if (value == "add") {
        this.dialog = true;
        this.updateEventValue = { title: "Add New Event" };
      } else {
        this.dialog = true;
        this.updateEventValue = { ...item, title: "Update Event" };
      }
    },
    async deleteEvent(event) {
      this.ConfirmDialog = false;
      this.loading = true;
      try {
        if (event.status) {
          const response = await EventService.deleteEvent(event.id);

          if (response.data.errorMessage == "") {
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: response.data.data,
                type: "success",
              },
              { root: true }
            );
            this.getEventList();
          } else {
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: response.data.errorMessage,
                type: "error",
              },
              { root: true }
            );
          }
        } else {
          this.ConfirmDialog = event.status;
        }
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unathorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
      this.ConfirmDialog = false;
      this.loading = false;
    },
    confimationDelete(id, value) {
      this.ConfirmDialog = value;
      this.deleteId = Number(id);
    },
    async pushNotification() {
      await EventService.pushNotificationService();
    },
    getParameterValues(param) {
      let index = window.location.href.indexOf("?") + 1;
      let url = window.location.href.slice(index).split("&");
      for (var i = 0; i < url.length; i++) {
        var urlparam = url[i].split("=");
        if (urlparam[0] == param) {
          return urlparam[1];
        }
      }
    },
  },
};
</script>
<style scoped>
.badgeActive {
  padding-inline: 4px;
  color: rgb(0, 0, 0);
  background-color: #efce6a;
  border-radius: 5px;
  text-align: center;
}
.badgeDeactive {
  padding-inline: 4px;
  color: white;
  background-color: #696866;
  border-radius: 5px;
  text-align: center;
}
</style>
