<template>
  <v-dialog v-model="dialog" persistent max-width="600px" style="z-index=999">
    <v-card>
      <v-card-title class="card-title-dialog">
        <span class="text-h6">Confirmation</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12"
              ><h4>Are you sure you want to delete this item?</h4>
              <p>This will be deleted permanently</p></v-col
            >
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('confirmation', { status: false, id: deleteId })"
        >
          Close
        </v-btn>
        <v-btn
          color="add-btn"
          type="submit"
          text
          @click="$emit('confirmation', { status: true, id: deleteId })"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    deleteId: {
      type: Number,
    },
  },
};
</script>
